import {InjectionToken} from '@angular/core';

const config = ENV;

const AppConfigService = new InjectionToken<MsAppConfig>('Application Config', {
  providedIn: 'root',
  factory: () => config.APP_CONFIG,
});

const WIDGET_OPTIONS = config.APP_CONFIG.nmdpWidget;
WIDGET_OPTIONS.appInfo = {
  name: config.APP_CONFIG.description,
  version: config.BUILD_VERSION,
};

export {AppConfigService, config, WIDGET_OPTIONS};
